@import "variables";
@import "bootstrap-custom";
@import "theme";
@import "micons";

$colWidthSm: (100% / 12);
$colWidthSix: (100% / 6);
$colWidthThree: (100% / 3);
$colWidthLg: (100% / 4);
$faFw: (18em / 14);
$line-height-base: (20 / 14);
$statColor: #225555;
$understated: #666;

// ng-click anchors should act like normal anchors
a, [ng-click], [data-ng-click], [x-ng-click], a[ui-sref], a.list-group-item, .dropdown-toggle, .clickable {
  cursor: pointer;
}

[ng-click] {
  user-select: none;
}

#app.loading:not(.modal-open), body.loading:not(.modal-open), body.loading:not(.modal-open), body.modalLoading, body.modalLoading .modal-body {
  cursor: wait !important;

  &.waiting, [ng-click], [data-ng-click], [x-ng-click], a[ui-sref], a.list-group-item, .dropdown-toggle, a.disabled, .btn[disabled] {
    cursor: progress !important;
  }
}

.isDoingSomething {
  cursor: wait !important;
}

// Todo: band-aid fix for https://github.com/angular-ui/bootstrap/issues/5183
body > [uib-modal-window]:not([modal-render]) {
  display: none;
}

#launcher {
  margin: 5px !important;
  z-index: 2 !important;
}

abbr {
  text-decoration: none;
}

h2, h3, h4 {
  font-weight: bold;
}

highchart {
  width: 100%;
  display: block;
}

.warningBanner {
  padding: 2px 10px;
  font-weight: bold;
  border-bottom: 1px solid black;

  &.envBanner {
    background-color: orange;

    span {
      background-color: peachpuff;
    }
  }

  &.blockedBanner {
    background-color: $state-danger-bg;
    color: $state-danger-text;
    padding: 10px 10px 5px 10px;
    font-size: 14px;
    border-bottom-color: $state-danger-text;
  }

  &.menuBanner {
    background-color: $state-info-bg;
    padding: 8px;
    font-size: 12px;
  }

  &.masqBanner {
    background-color: #ddd;

    span {
      background-color: lemonchiffon;
    }

    .pull-right {
      font-weight: normal;
    }
  }

  span:not(.fa) {
    display: inline-block;
    border: 1px solid black;
    padding: 1px 10px;
  }
}

.modal-header {
  .modal-title {
    line-height: 1;
  }

  .close {
    color: #fff;
    opacity: 0.5;

    &:hover {
      color: #fff;
      opacity: 0.9;
    }
  }
}

.warningBackground {
  background-color: $alert-warning-bg;
}

.clearLeft {
  clear: left;
}

.clearRight {
  clear: right;
}

.clearBoth {
  clear: both;
}

.swatch {
  display: inline-block;
  width: 15px;
  height: 15px;
}

.listColumnSelector {
  float: right;
  position: relative;
  top: 12px;
}

.noHighchartsData {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.unbrokenText {
  white-space: nowrap;
}

.linkDisabled {
  cursor: not-allowed;
  pointer-events: none;
  color: #333;
}

.radioInline .radio-inline, .radioInline.radio-inline {
  input[type=radio] {
    height: 12px;
  }
}

.form-control.dimensionsLarge {
  width: 200px;
  display: inline-block;
}

.form-control.dimensionsMid {
  width: 150px;
  display: inline-block;
}

.form-control.dimensionsLessMid {
  width: 120px;
  display: inline-block;
}

.unitDimensions, .moneyDimensions {
  width: 80px;
}

.form-control.miniUnitDimensions, .form-control.unitDimensions, .form-control.moneyDimensions {
  width: 80px;
  display: inline-block;
}


.form-control.miniUnitDimensions {
  width: 60px;
}

.form-control.mapDimensions {
  width: 110px;
  display: inline-block;
}

.form-control.autoWidth {
  width: auto;
}

.selectionContainer {
  h3 {
    margin-top: 5px;
  }

  .row {

    .thumbnailColumn {
      margin-bottom: 20px;

      .thumbnail {
        height: 100%;
        margin-bottom: 10px;
        background-color: #f9f9f9;

        &.itemSelected {
          border-color: $state-success-text;
          background-color: $state-success-bg;
        }

        &:hover:not(.itemSelected):not(.itemDisabled) {
          background-color: #e9e9e9;
        }

        &.itemDisabled {
          cursor: default;
        }
      }
    }
  }

  .selectorIconContainer {
    font-size: 25px;
  }

  .selectedIcon {
    color: $state-success-text;
  }
}

.tableSelection {
  .selected {
    border-color: $state-success-text;
    background-color: $state-success-bg;

    .fa {
      color: $state-success-text;
    }
  }

  .fa {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .selectionContainer {
    .row {
      flex-wrap: wrap;
      display: flex;
    }
  }
}

// fix up what appears to be a bootstrap oversight
.btn-link {
  &.btn-xs {
    font-size: 12px;
  }

  &.btn-danger, &.text-danger {
    color: #a94442;

    &:hover {
      color: #843534;
    }
  }
}

.alertBreathingSpace {
  margin-top: 5px;
}

.breakWord {
  word-wrap: break-word;
}

.brokenWordAlertContainer {
  .alert {
    max-width: 450px;
    word-wrap: break-word;
  }
}

.insideTableDates {
  > div:first-child {
    display: inline-block;
    max-width: 100px;
  }

  > div:nth-child(2) {
    display: inline-block;
    max-width: 100px;
  }
}

.alertCompact {
  .alert {
    margin-bottom: 2px;
  }

  p {
    margin-bottom: 0;
  }
}

.constrainedFormControls {
  .form-group {
    margin-bottom: 0;
  }
}

.alertSimple {
  background-color: #fcfcfc;
  border-color: #eee;
}

.btn-default[disabled] {
  color: #bbb;
}

.input-group .form-control {
  z-index: 3;
}

input[type=radio], input[type=checkbox] {
  margin-top: 0;
}

.checkbox {
  label {
    .help-block {
      margin: -3px 0 2px 0;
    }
  }
}

.inactive {
  color: #aaa
}

.hasMessage {
  border: 1px dashed #999;
  padding: 4px;
}

.list-unstyled {
  input[type=radio], input[type=checkbox] {
    margin-top: 0;
  }
}

.small .radio input[type=radio], .small .checkbox input[type=checkbox] {
  margin-top: 0;
}

.checkbox-inline {
  input[type=checkbox], input[type=radio] {
    margin-top: 0;
  }
}

.percentageControlGroup {
  max-width: 110px;
}

.progress-bar {
  max-width: 100%;
  color: #000;
}

.formCondensed label.control-label {
  padding-top: 0;
}

.formCondensed.objectiveStyle label.control-label {
  padding-top: 3px;
}

.formControlRelated {
  margin-top: 3px;
}

form-submission-message {
  position: sticky;
  top: 10px;
  z-index: 100;
  display: block;
}

.form-horizontal .form-group-sm .control-label {
  padding-top: 6px;
}

.form-horizontal .formVertical .form-group {
  margin-left: auto;
  margin-right: auto;
}

legend.small {
  font-weight: 400;
  margin-bottom: 10px;
}

ul.form-control-static {
  padding-left: 15px;
}

.form-group .list-unstyled {
  padding-top: 0;
}

td .list-unstyled {
  margin-bottom: 0;
}

.list-group-item {
  p {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .overlayLabelItem {
    right: 15px;
  }

  .historyEvents {
    margin: 0 0 0 1.55em;
  }

  &.active {
    .text-muted {
      color: whitesmoke;
    }
  }
}

.small:empty, .list-group:empty, code:empty {
  display: none;
}

h5 {
  font-weight: bold;
}

.list-group-item {
  padding: 7px 9px;
}

h1, h2, h3, h4, h5, h6, .smallWithPrefix {
  small, code {
    &:not(:empty):before {
      content: ' - ';
    }
  }
}

.breadcrumbContainer {
  font-size: 10px;
  margin-bottom: 2px;
}

.itemType {
  color: $understated;
}

h1 {
  font-weight: bold;
  font-size: 16px;
}

.parentHeading {
  padding-bottom: 8px;
  border-bottom: 1px dotted #bbb;
  margin-bottom: 8px;
}

h2 {
  font-size: 15px;
}

h3 {
  font-size: 14px;
}

h4 {
  font-size: 13px;
}

h5 {
  font-size: 12px;
}

.alert.small, small .alert, .small .alert {
  padding: 5px 5px;
  margin-bottom: 5px;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control, tags-input[disabled] .tags, .input-group-addon[disabled] {
  background-color: #fcfcfc;
  border: 1px solid #eee;
}

.alert {
  &:empty {
    display: none;
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }
}

.form-inline {
  margin-bottom: 20px;
}

.uiLogo {
  max-width: 170px;
  max-height: 30px;
}

.reportLogo {
  max-width: 340px;
  max-height: 60px;
}

.dataPanel {
  position: relative;
  width: 100%;
  overflow: auto;

  .table {
    &:last-child {
      margin-bottom: 0;
    }

    > tbody > tr > th {
      white-space: nowrap;
    }
  }
}

.table {
  pre {
    overflow: auto;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}

.reportGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-left: -5px;
  margin-right: 5px;

  .reportGridItem {
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    flex-basis: calc(#{$colWidthSix} - 10px);

    .stat {
      color: $statColor;
      font-size: 16px;
    }

    .caption {
      font-size: 10px;
    }
  }
}

.nav-tabs {
  > li {
    margin-bottom: -1px;
  }
}

nav {
  a {
    font-size: 13px;
    user-select: none;
  }
}

.checkbox, .radio {
  label {
    user-select: none;
  }
}

th a {
  user-select: none;
}

.popover {
  font-size: 12px;
}

.popover-content {
  color: $gray-dark;

  ul {
    padding-left: 15px;
    margin-bottom: 0;
  }
}

.infoIconLink.btn {
  padding: 0;
  margin: 0;
}

.infoIconLink, .infoIconLink:active {
  color: $gray-dark;
}

.dangerColor, .dangerColor:active, .dangerColor:hover {
  color: $state-danger-text !important;
}

th .infoIconLink {
  float: right;
}

.datePicker {
  $fullDayBg: #5cb85c;
  $fullDayBorder: #4cae4c;
  $partialDayBg: #f0ad4e;
  $partialDayBorder: #eea236;

  margin-bottom: 10px;
  overflow-x: hidden;

  + .fa-ul .fa-li {
    &.fullDay {
      color: $fullDayBg;
    }

    &.partialDay {
      color: $partialDayBg;
    }
  }

  table[role="grid"] {
    width: 100%;

    .fullDay .btn-default {
      color: #fff;
      background-color: $fullDayBg;
      border-color: $fullDayBorder;

      &:hover, &.active, &:active {
        background-color: darken($fullDayBg, 10%);
        border-color: darken($fullDayBorder, 12%);
      }

      &:focus, &.active:hover {
        background-color: darken($fullDayBg, 17%);
        border-color: darken($fullDayBorder, 25%);
      }
    }

    .partialDay .btn-default {
      color: #fff;
      background-color: $partialDayBg;
      border-color: $partialDayBorder;

      &:hover, &.active, &:active {
        background-color: darken($partialDayBg, 10%);
        border-color: darken($partialDayBorder, 12%);
      }

      &:focus, &.active:hover {
        background-color: darken($partialDayBg, 17%);
        border-color: darken($partialDayBorder, 25%);
      }
    }
  }
}

.help-block {
  margin-top: 2px;
}

.form-group .help-block {
  margin-bottom: 0;
}

tags-input .tags .tag-item {
  margin-top: 2px;
  line-height: 19px;
  height: 20px;
  font-size: 12px;
  margin-right: 1px;
}

tags-input .tags .input {
  margin: 0;
  height: 22px;
  font-size: 12px;
  font-family: $font-family-sans-serif;
}

.avatarContainer {
  background-color: #f5f5f5;
}

header.app {
  margin-bottom: 0;
  padding: 10px;

  .brand {
    text-align: center;

    a {
      outline: none;
    }

    &.theme-logo a {
      height: 32px;
      display: block;
      background: transparent no-repeat 0 0;
    }
  }

  .menuIcon {
    .dropdownMenuIcon {
      font-size: 20px;
    }

    .dropdownMenuCaret {
      font-size: 10px;
      vertical-align: super;
    }
  }

  .header-title {
    .btn-group {
      display: inline-block;

      .dropdown-toggle, .dropdown-menu.dropdownWithArrow {
        margin-left: 11px;
      }
    }
  }

  .sessionInfo {
    .dropdown-panel {
      margin-top: 6px;
    }

    > a {
      display: block;
      position: relative;
      padding: 10px 11px 10px 0;

      &:hover {
        text-decoration: none;
      }

      .userIdentifier {
        white-space: nowrap;
        line-height: 22px;
        position: relative;
        top: 1px;

        .avatar {
          width: 22px;
          height: 22px;
          vertical-align: baseline;
          position: relative;
          top: 1px;
        }

        .username {
          display: inline-block;
          max-width: 16em;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .networkIdentifier {
        font-size: 9px;
        line-height: 9px;
        white-space: nowrap;
      }

      .menuIcon {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -7px;
      }
    }

    .avatarWithNetwork {
      .userIdentifier {
        line-height: 13px;

        .avatar {
          width: 13px;
          height: 13px;
          top: 0;
          left: 1px;
        }
      }
    }
  }

  .dropdown-panel:not(:empty) {
    left: -10px;
    right: -10px;

    &:before, &:after {
      content: "";
      right: 16px;
      left: auto;
    }
  }
}

.dropdown-menu.dropdownWithArrow:not(:empty) {
  &:before, &:after {
    content: none;
    width: 0;
    height: 0;
    border: 11px inset transparent;
    border-top-width: 0;
    position: absolute;
    left: 10px;
  }

  &:before {
    border-bottom-color: #ccc;
    border-bottom-color: rgba(0, 0, 0, .15);
    top: -11px;
  }

  &:after {
    border-bottom-color: #fff;
    top: -10px;
  }

  > li > a, > li.dropdown-header {
    padding-left: 12px;
    padding-right: 12px;
  }
}

#app {
  .chartOptions {
    .dropdown-menu {
      left: auto;
      right: 0;
      padding: 5px 0;
      margin-top: 5px;

      &:before, &:after {
        content: "";
        right: 0;
        left: auto;
      }
    }

    .dropdown-menu.uib-datepicker-popup {
      right: auto;
    }
  }
}

ul.fullInline {
  display: inline;
}

.header-title {
  .list-inline {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 11px;

    > li {
      padding: 0;
      margin: 0;

      > a {
        padding: 8px 11px;
        display: inline-block;
        height: 33px;
        margin: 0;
      }
    }
  }

  button {
    padding: 4px 7px;
    margin-top: 3px;
  }

  a:hover, a:focus, .active {
    text-decoration: none;
  }
}

.searchListForm {
  margin: 10px 0;
}

.searchOptions {
  h5 {
    font-size: 12px;
    color: #777;
    font-weight: normal;
    margin-bottom: 0;
  }

  li {
    display: block;
  }

  a {
    display: block;
    padding: 5px;
    border-radius: 2px;

    &:hover {
      background-color: #ddd;
      text-decoration: none;
    }

    .searchOptionText {
      display: table-cell;
      width: 100%;
    }

    .searchOptionData {
      display: table-cell;
      font-size: 11px;
    }

    .fa {
      font-size: 12px;
      display: inline-block;
      padding-right: 2px;
    }
  }
}

.releaseNotesContainer {
  font-size: 14px !important;

  img {
    max-width: 100%;
  }

  .label {
    font-size: 70% !important;
  }

  h1 {
    font-size: 17px;
  }

  h2 {
    margin-top: 0;
    font-size: 17px;
    margin-bottom: 5px;

    a {
      color: #333;
    }
  }

  h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  h4 {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .publishTime {
    font-size: 11px;
  }

  .noteContainer {
    h2 {
      margin-top: 15px;
    }

    h3 {
      margin-top: 15px;
    }

    h4 {
      margin-top: 15px;
    }
  }

  .subTitle {
    font-size: 15px;
    color: #444;
  }

  .theNote {
    border: 1px solid #eee;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.12),0 2px 4px 0 rgba(0,0,0,.08)
  }
}

.sideBySideControl {
  .form-control {
    display: inline-block;
    width: auto;
  }

  .moneyControl {
    max-width: 110px;
  }
}

.moneyControl {
  max-width: 150px;
}

.slimControl {
  max-width: 75px;
}

.fullWidthJustified {
  display: table;

  .leftJustify {
    display: table-cell;
    width: 100%;
  }

  .rightJustify {
    display: table-cell;
  }
}

.dropdown-menu > .active > a, .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  color: #fff;
  background-color: #337ab7;
}

.dropdown-menu > li > a {
  &.typeSearchNew {
    border-top: 1px solid #ccc;
  }

  &.globalSearchResultItem {
    white-space: normal;
    position: relative;
    padding-left: 30px;

    .fa, .micon {
      position: absolute;
      left: 6px;
      top: 3px;
      line-height: inherit;
    }

    small {
      display: block;

      &:empty {
        display: none;
      }
    }
  }
}

.singleSidedPage {
  input, select, textarea {
    max-width: 600px;
  }
}

.tagMirror {
  .CodeMirror {
    height: 400px;
  }
}

.hasError {
  color: $state-danger-text;
}

.layout .has-error .select2-container .select2-choice, .modal-content .has-error .select2-container .select2-choice {
  border-color: $state-danger-text;
}

.has-error .CodeMirror {
  border-color: #a94442;
}

.CodeMirror {
  border: 1px solid #ddd;
  font-size: 12px;
  height: 225px;
  margin-bottom: 15px;
}

.smallerMirrorContainer .CodeMirror {
  height: 200px;
}

.emailMirrorContainer .CodeMirror {
  height: 400px;
}

.layoutMirrorContainer .CodeMirror {
  height: 550px;
}

.layoutPreview {
  max-height: 200px;
  overflow: auto;
}

textarea.codeContainer {
  font-family: $font-family-monospace;
  font-size: 10px;
  overflow: auto;
  min-height: 150px;
  margin-bottom: 15px;

  &.codeContainerMedium {
    min-height: 100px;
  }

  &.codeContainerSmall {
    min-height: 50px;
  }
}

.CodeMirror {
  font-size: 90%;
}

.unread {
  color: $understated;
}

.searchResults {
  margin-top: 20px;

  li {
    margin-top: 22px;
    padding-bottom: 7px;
    padding-left: 25px;
    border-bottom: 1px dotted #e5e5e5;
    left: -25px;

    .searchResultType {
      font-size: 9px;
      color: #888;
      position: absolute;
      top: -15px;
      left: 25px;
    }

    h5 {
      margin-bottom: 0;
    }

    legend {
      font-size: inherit;
      font-weight: bold;
    }

    p {
      margin-bottom: 0;
      font-size: 12px;
    }
  }
}

.panel-heading .btn-xs {
  margin-top: -2px;
}

.panel-body, [collapse], [uib-collapse], td[colspan], .well {
  &.chart {
    padding-left: 0;
    padding-right: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }

  > ul:last-child, > ol:last-child, > dl:last-child, > p:last-child, > .list-group:last-child, .table:last-child {
    margin-bottom: 0;
  }

  > fieldset {
    > ul:last-child, > ol:last-child, > p:last-child, > .list-group:last-child, .table:last-child {
      margin-bottom: 0;
    }
  }

  &.widget-sm {
    max-height: 200px;
    overflow-y: auto;
  }
}

.well {
  padding: 5px;
  margin-bottom: 10px;
}

.dateTargetingAddTime button {
  margin-top: 12px;
}

.small .sectionTitle {
  padding: 3px 0 3px 3px;
  background-color: #f5f5f5;
  border-bottom: 0;
  margin-bottom: 5px;
}

.sectionTitle, legend {
  padding: 7px 0;
  font-size: $font-size-h4;
  font-weight: bold;
  color: $understated;
  clear: both;
  margin: 7px 0 2px 0;

  &.sectionFlat {
    margin: 0;
    padding-top: 0;
  }

  &.sectionBordered {
    border-top: 1px dotted #bbb;
    padding-top: 4px;
    margin-top: 1px;
    font-size: 14px;
    margin-bottom: 0;

    .pull-right {
      position: relative;
      top: -2px;
    }
  }

  &.sectionCollapse {
    margin-top: 15px;
    border-top: 1px dotted #bbb;
  }
}

.btn-xs {
  line-height: 1.3;
}

legend {
  border: none;
  margin-bottom: 0;
}

.activeConnection {
  color: #fff;
  background-color: lightblue;
}

a.disabled {
  pointer-events: none;
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;

  &.disabledStrong {
    filter: none;
    opacity: 1;
    font-weight: bold;
    color: #333;
  }
}

.toast {
  width: 400px !important;
  opacity: 0.98 !important;
}

.fancySpan {
  width: 100%;
  text-align: center;
  border-bottom: 1px dashed #333;
  line-height: 0.1em;
  margin: 10px 0;

  &.fancySpanSmall {
    margin: 4px 0;
  }

  span {
    background-color: #fff;
    padding: 0 10px;
    position: relative;
    top: 1px;
  }
}

.dialogAddStuff {
  background-color: #428bca;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .modal-title {
    color: #fff;

    small {
      color: inherit;
    }
  }

  .close {
    margin-left: 15px;
  }
}

.overlayLabelContainer {
  position: relative;

  .overlayLabelItem {
    position: absolute;
    z-index: 1;
    right: 4px;
  }
}

.listWithSpacedLinks li {
  border-right: 1px dotted #333;

  &:last-child {
    border-right: none;
  }
}

.scrollableItem {
  overflow: auto;
  padding: 0 0 2px 0;
  margin: 0 0 15px 0;
}

.adnThumbnail {
  float: left;
  margin: 0 15px 15px 0;
}

.list-group-item .adnThumbnail {
  margin: 0 10px 10px 0;
}

.form-group {
  .chartOptions {
    > .btn, div > .btn {
      margin: 5px 0 0 0;
    }
  }
}

.chartOptions, .columnOptions, .popover-content {
  div:not(.btn-group) > .btn, > .btn {
    margin: 5px 2px 10px 2px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    white-space: nowrap;
  }
}

td .popover-content {
  ul {
    list-style-type: disc;
    padding-left: 10px;
  }

  li {
    white-space: normal;
  }
}

hr.formRowSeparator {
  margin-top: -2px;
  margin-bottom: 8px;
}

.adnDialog {
  .modal-dialog {
    cursor: default;
  }

  .addStuffItem {
    border: 1px dotted #ddd;
    border-radius: 4px;
    padding: 10px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:hover {
      background-color: #f5f5f5;

      .table-striped > tbody > tr {
        background-color: transparent;
      }
    }

    .addStuffThumbnail {
      float: left;
      margin-right: 10px;
      overflow: hidden;

      &.widgetThumbnailContainer {
        text-align: center;

        img {
          background-color: #fff;
          border: 1px solid #eee;
        }
      }
    }

    .adnThumbnail {
      margin: 0 10px 0 0;
    }

    .details {
      padding-left: 20px;

      .table:last-child {
        margin-bottom: 0;
      }
    }

    .list-group-item {
      background-color: transparent;
    }
  }

  .addStuffSelection {
    border-top: 1px dotted #ddd;
    padding-top: 10px;
  }

  h4, h5 {
    margin-top: 0;
  }
}

.pStyle {
  margin-bottom: 8px;
}

.modal-header {
  &.form-inline {
    margin-bottom: 0;

    label.modal-title {
      font-weight: 500;
    }
  }

  .form-inline {
    margin: -4px 0 0;
  }
}

.modal-body {
  cursor: default;

  &.creativePreview {
    overflow: auto;
    text-align: center;
    max-height: 500px;
  }

  .sectionTitle:first-child {
    margin-top: 0;
  }
}

.widgetTagSelector {
  margin-top: 10px;

  li {
    padding: 1px 0;
  }

  .disabled {
    color: black;
    font-weight: bold;
  }
}

.rowWarning {
  td {
    background-color: #fde9f0;
  }
}

.reportTemplateWorkspace .fa-times-circle {
  position: relative;
  top: 3px;
}

.gridsterWorkspace {
  margin-bottom: 1em;

  // overriding a very unhelpful important class from the gridster css.
  .gridster-mobile {
    height: 90px !important;
  }

  &.editableWorkspace {
    border: 2px dashed #ddd;
    background-color: #eee;

    .gridster-item {
      background-color: #fff;
      padding: 0 8px;

      .sectionTitle + div {
        height: calc(100% - 50px);
      }
    }

    .sectionTitle {
      margin: 8px 0 8px 0;
      padding: 3px;
      background-color: #f9f9f9;
      border: 1px solid #eee;
    }

    .panel-heading, .panel-body {
      padding-left: 8px;
      padding-right: 8px;
    }

    .panel-body {
      margin-top: 8px;
    }
  }

  .gridster-item {
    .sectionTitle + div {
      height: calc(100% - 25px);
      overflow: auto;

      > .row {
        margin-left: 0;
        margin-right: 0;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .deleteWidget {
    cursor: pointer;
    position: relative;
    left: -2px;
  }
}

.workspaceView {
  h3 {
    margin-top: 0;
    padding: 0;
  }

  .gridster-item {
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    padding: 10px;
  }
}

.form-group .gridsterWorkspace, .row .gridsterWorkspace {
  margin-top: 0;
}

.form-group-sm {
  margin-bottom: 10px;

  .form-control-static .small {
    line-height: (1.5 / .85);
  }
}

.form-control-static + .help-block {
  margin-top: -7px;
}

.form-group-condensed {
  margin-bottom: 2px;
}

.fixedFormButtons {
  margin-bottom: 60px;

  &.formButtonsExtra {
    margin-bottom: 100px;
  }
}

@media (min-width: 768px) {
  .container-small {
    width: 600px;
  }
  .container-large {
    width: 970px;
  }
}
@media (min-width: 992px) {
  .container-small {
    width: 700px;
  }
  .container-large {
    width: 1170px;
  }
}
@media (min-width: 1200px) {
  .container-small {
    width: 900px;
  }
  .container-large {
    width: 1500px;
  }
}

.container-small, .container-large {
  max-width: 100%;
}

#app .fixedFormButtons .formButtons {
  position: fixed;
  bottom: 0;
  margin-top: 0;
  margin-left: -15px;
  padding: 10px 15px;
  background-color: #fff;
  z-index: 200;

  .checkbox {
    margin-top: 0;
  }
}

.formButtons {
  margin-top: 20px;

  .has-error .help-block, .has-warning .help-block {
    padding-left: 10px;
  }
}

.editableWorkspace .dragDropHandle {
  cursor: move;
}

.gridster-item {
  // changing the default gridster behaviour so charts can render correctly
  display: block;
  opacity: 0;

  .reportComponent .thumbnail {
    margin-bottom: 5px;
    padding: 0;
    border: 0;

    img {
      height: auto;
      width: auto;
      max-height: 45px;
    }
  }
}

table {
  .selectedRow {
    > td {
      background-color: #eee;
    }
  }

  td {
    .toggleButton {
      padding: 1px 4px;
    }
  }

  .toggleButton {
    display: block;
    font-size: 16px;
    padding: 0 4px;
  }
}

label.bulkEdit {
  font-weight: normal;
  font-size: 11px;
  display: block;
  cursor: pointer;
  user-select: none;

  input {
    transform: scale(0.8);
    position: relative;
    top: 3px;
  }
}

.reportTemplateView {
  .thumbnail img {
    max-height: 50px;
  }
}

dd .list-inline {
  margin-bottom: 0;
}

.form-horizontal {
  .doubleColumned {
    .radio, .checkbox {
      padding-top: 0;
    }
  }
}

.doubleColumned {
  column-count: 2;
}

.totalsTable {
  clear: both;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  .fa {
    margin-right: 2px;
  }

  li {
    display: inline-block;
    padding: 2px;
    width: 100%;
  }

  li:nth-child(even) {
    background-color: #eee;
    padding: 4px 2px;
  }

  .titleField {
    display: table-cell;
    width: 100%;
  }

  .numericColumn {
    color: $statColor;
    display: table-cell;
    font-weight: bold;
    white-space: nowrap;
  }
}

.commaSeparated {
  span.commaSeparate:not(:last-child):after {
    content: ",\00a0";
    display: inline-block;
  }
}

.small .fa-times {
  position: relative;
  top: 3px;
}

.list-inline.commaSeparated {
  margin: 0;

  > li {
    padding: 0;

    &:not(:last-child):after {
      content: ",\00a0";
    }
  }
}

.gridster-loaded .gridster-item {
  opacity: 1;
}

.gridsterWorkspace:not(.editableWorkspace) .gridster-loaded .gridster-item {
  transition: opacity .3s 50ms;
}

.creativeLibraryItem legend {
  font-size: inherit;
  font-weight: bold;
}

.adRenderPreview {
  transform-origin: top left;
}

.creativePreviewThumbnail {
  display: block;
  width: 126px;
  height: 96px;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 2px;
  background-color: #fff;
  margin-right: 10px;
}

.creativePreviewValidationContainer {
  overflow: auto;
  overflow-wrap: break-word;
}

.breakAll {
  word-break: break-all;
}

td > .creativePreviewThumbnail {
  margin-right: 0;
}

#app:not(.denuded) main article h1 {
  margin-top: 0;
}

#app {

  footer {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .mainNavSection {
    a:hover + .dropdown-menu, .dropdown-menu:hover {
      display: block;
      margin-top: 0 !important;
    }

    .dropdown-menu a {
      font-size: 12px;
      padding: 3px 12px;
    }

    .fa-circle {
      display: none;
    }

    .activeCircular {
      .fa-circle {
        display: inline;
        margin-right: 5px;
      }
    }

  }

  main {
    article {
      position: relative;
    }

    .sidebar {
      .nav > li, .nav > li li {
        &.subHeading {
          padding: 10px;
          color: white;
          font-size: 13px;
          font-weight: bold;
        }

        > a {
          padding: 10px;

          .micon, .fa {
            margin: 0 10px 0 0;
          }
        }

        &.divider {
          height: 1px;
          margin: 9px 0;
          overflow: hidden;
          background-color: #e5e5e5;
        }
      }

      .copyright {
        a {
          text-decoration: underline;
          color: $text-muted;

          &:hover {
            text-decoration: none;
          }
        }

        font-size: 10px;
        margin-bottom: 0;
        padding: 10px;
        line-height: 1;
      }
    }

    .notifications {
      position: relative;
      padding-top: 40px;

      .notificationTabs {
        height: 0;
        overflow: hidden;

        &.in {
          height: auto;
        }

        .notificationTab {
          .tabToggle {
            display: block;
            position: absolute;
            top: 0;
            width: 40px;
            text-align: center;
          }

          .tabContent {
            padding: 15px;
            display: none;

            h3 {
              font-weight: bold;
            }

            .list-group {
              margin-bottom: 0;
            }
          }

          &.active .tabContent {
            display: block;
          }
        }
      }

      .notificationResult {
        padding: 5px 5px 5px 30px;
        position: relative;

        &.unread {
          font-weight: bold;
        }

        .micon {
          position: absolute;
          top: 5px;
          left: 7px;
          line-height: inherit;
        }
      }
    }
  }

  .signInRow {
    border-radius: 10px;
    padding: 10px;
    align-items: center;
  }

  &.signInContainer {
    background: url(https://regalia.adnuntius.com/ui/bg-melbourne-2550x1440.jpg) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;

    &.nz {
      // sourced from https://en.wikipedia.org/wiki/File:New_Zealand_snow.jpg
      background-image: url(https://regalia.adnuntius.com/ui/New_Zealand_snow.jpg);
    }

    &.mtcook {
      // sourced from https://en.wikipedia.org/wiki/File:Mt_Cook,_NZ.jpg
      background-image: url(https://regalia.adnuntius.com/ui/Mt_Cook_NZ.jpg);
    }

    &.melbourne {
      background-image: url(https://regalia.adnuntius.com/ui/bg-melbourne-2550x1440.jpg);
    }

    &.oslo {
      // sourced from https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Frognerkilen_-_2011-09-07_at_21-36-46.jpg/1280px-Frognerkilen_-_2011-09-07_at_21-36-46.jpg
      background-image: url(https://regalia.adnuntius.com/ui/oslo.jpg);
    }

    &.philstar {
      background-image: url(https://regalia.adnuntius.com/label/philstar/adn-bg.jpg);
    }

    &.blueoryxcalt {
      background-image: none;
    }

    &.mediasquare {
      background-image: url(https://assets.adnuntius.com/vebZJuGog2P5uhDJnTCj9xiMv6FNVVNWoGQdir0jhG8.png);
    }

    &.ais {
      background-image: url(https://regalia.adnuntius.com/label/ais/THAN_1920x1080-BG.jpeg);
    }

    &.prisa {
      background-image: url(https://regalia.adnuntius.com/label/prisa/bg.jpg);
    }

    .panel {
      border-width: 3px;
    }

    .panel-heading {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .panel-title {
      font-size: 18px;
    }

    .panel-body {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .signInFlexContainer {
      display: flex;
      min-height: 80vh;
      align-items: center;
      justify-content: center;

      .signInSubContainer {
        max-width: 900px;
        background-color: #f0f0f0;
        border: 10px solid #ddd;
        border-radius: 5px;
        padding: 15px;
      }
    }

    .panel {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .btn-link.pull-left {
      padding-left: 0;
    }

    .signInFooter {
      margin-top: 4px;
    }

    footer {
      padding: 8px;
      border-top: 0 none;
      background-color: transparent;
      position: relative;
      color: #ddd;

      a {
        color: #ddd;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      p {
        margin: 0;
      }
    }
  }
}

.list-group.timestamped .list-group-item {
  padding-top: 10px;

  .timestamp {
    font-size: 10px;
    white-space: nowrap;
  }
}

.reachAnalysisCompetitors {
  max-height: 1000px;
  overflow-y: auto;
}

.topStatsWidgetContainer {
  max-height: 450px;

  .topStatsTableContainer {
    max-height: 350px;
    overflow-y: auto;
    display: block;
    scrollbar-width: thin;
  }
}

.articlesWidgetContainer {
  max-height: 1250px;

  .articlesTableContainer {
    max-height: 1150px;
    overflow-y: auto;
    display: block;
    scrollbar-width: thin;
  }
}

.analysisWidgetContainer {

  &.analysisReportWidgets > div, &.analysisReportWidgets > .limitSize {
    max-height: 800px;
  }

  .datesRequested .adnDatePicker table .btn {
    padding: 4px 5px;
  }

  .datesRequested .adnDatePicker .uib-button-bar {
    padding: 5px 5px 2px 5px;
  }

  .datesRequested .adnDatePicker .uib-button-bar .btn-sm {
    padding: 1px 4px;
  }

  .datesRequested {
    min-height: 430px;
  }

  > div, .limitSize {
    max-height: 700px;
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
  }

  form {
    p.text-right > a {
      margin-right: 15px;
    }
  }
}

.frontPageContent {
  h1 {
    font-size: 50px;
    margin-top: -10px;
  }

  h2 {
    font-size: 35px;
  }
}

@media (min-width: 768px) {
  .signInRow {
    display: flex;
  }
}

.singleColumnSignIn {
  max-width: 360px;
}

form {
  .ta-root {
    margin-bottom: 10px;
  }

  .ta-scroll-window.form-control {
    min-height: 125px;
  }

  .ta-scroll-window > .ta-bind {
    min-height: 125px;
  }

  .largeContentBox {
    .ta-scroll-window > .ta-bind {
      min-height: 325px;
    }
  }
}

.table tbody.simpleTbody {
  border-top: 0;
}

.simpleLabel {
  input[type=checkbox] {
    margin: 0;
    position: relative;
    top: 2px;
  }

  font-weight: normal;
  display: inline;
}

.checklistSimple label {
  font-weight: normal;
}

.targetingSelected {
  background-color: lighten($brand-success, 40%);
}

.editingSelected {
  background-color: lighten($brand-info, 35%);
}

.targetingSummary {
  > .row > div {
    margin-bottom: 1em;
  }

  h4 {

    &:empty {
      display: none;
    }

    font-weight: normal;

    .btn {
      position: relative;
      top: -3px;
    }

    .checkbox {
      padding-top: 0;
      margin: 0;
      font-size: 85%;
      min-height: 0;

      &.pull-right {
        label {
          padding-left: 0;
          padding-right: 20px;
        }

        input[type="checkbox"] {
          margin: 0;
          right: 0;
          top: 0;
        }
      }

      &.disabled label {
        color: #777;
      }
    }
  }
}

.expandControls {
  margin-top: 20px;

  h4 {
    background-color: #f5f5f5;
    padding: 6px;
    font-size: 13px;
    border-radius: 3px;
  }
}

.limitedListGroup {
  .list-group {
    margin-bottom: 0;
  }
}

.list-group h4 {
  background-color: #f5f5f5;
  padding: 6px;
  font-size: 13px;
  border-radius: 3px;
  margin: 5px 0 10px 0;
}

.btnGroupExplanation {
  margin-top: 2px;
}

.list-group-item {
  h4 .btn-group {
    position: relative;
    right: -3px;
  }

  > .btn-group {
    position: relative;
    top: 8px;
    right: 3px;
  }
}

.modal-content .targetSummary {
  font-size: 11px;
}

.targetSummary {
  .fa-2x {
    float: left;
    margin-right: 4px;
    padding-bottom: 10px;
  }

  dl {
    margin-bottom: 0;
  }

  dd, dt {
    line-height: 1;
  }

  dt {
    margin-bottom: 4px;
  }
}

// the chevron a big icon... have to cut it down to size across the board.
.fa-chevron-down, .fa-chevron {
  font-size: 85%;
}

td.actions, th.actions {
  text-align: center;
}

.layout {
  position: relative;
}

.layout, .modal-content {
  .select2-default {
    color: #999 !important;
  }

  .has-warning .select2-container .select2-choice, .modal-content .select2-container .select2-choice {
    border-color: $alert-warning-text;
  }

  .select2-container {
    width: 100%;
    min-width: 200px;

    .select2-drop {
      color: $gray;
    }

    .ui-select-choices-row:hover {
      background-color: #337ab7 !important;
      color: #fff !important;
    }

    .select2-results .select2-highlighted {
      background-color: inherit !important;
      color: inherit !important;

      &:hover {
        background-color: #337ab7 !important;
        color: #fff !important;
      }
    }

    .select2-results .select2-disabled {
      &:hover {
        background-color: #f0f0f0 !important;
        color: inherit !important;
        cursor: default !important;
      }
    }

    .select2-choice {
      background: none;
      border-color: $input-border;

      .select2-chosen {
        position: relative;
        top: -1px;
      }

      abbr {
        top: 5px;
      }

      .select2-arrow {
        background: none;
        border: none;

        b {
          background-position: 0 -1px;
        }
      }
    }

    .dropdown-content > div {
      color: $input-color-placeholder;
      padding: 0 5px 5px 5px;
    }
  }

  .select2-container-active, .select2-drop-active {
    border-color: $input-border-focus;

    .select2-choice {
      border-color: $input-border-focus;
    }
  }

  .ui-select-container {
    .ui-select-no-choice {
      padding: 10px;
    }

    .ui-select-search {
      min-width: 100%;
    }

    &.ui-select-bootstrap {
      .ui-select-match-item {
        margin: 1px 3px 2px 0;
      }

      .ui-select-match .ui-select-match-close {
        font-size: 15px;
        position: relative;
        top: 1px;
        opacity: .4;
      }
    }
  }

  .formControlRelated {
    .ui-select-container {
      min-width: 0;
      width: initial;

      .ui-select-match {
        min-width: 200px;
        display: inline-block;
      }
    }
  }
}

.listRowHeader {
  th {
    a {
      display: block;
      color: $gray-dark;
      position: relative;

      &.disabledSort {
        cursor: not-allowed;
        point-events: none;
      }

      &.selectedParam {
        font-weight: bold;
      }

      .fa {
        color: $gray;
        position: relative;
        top: 2px;
      }
    }
  }
}

.bulkActionHolder {
  margin-bottom: 10px;

  .selectionText {
    padding: 2px 0 0 5px;
  }
}

table {
  font-size: 100%;

  &[role='grid'] {
    outline: none;
  }

  &.table, &.table-condensed {
    > tbody > tr > td.tableContainer {
      padding: 10px 20px;
    }

    .btn-xs {
      font-size: 10px;
      padding: 1px 3px;
    }
  }

  .tableExpander {
    width: 10px;
    text-align: center;
    vertical-align: middle;
  }
}

.label {
  font-size: 90%;
}

.table .label {
  font-size: 80%;
}

.table .btn-xs, .btn-group-xs > .btn {
  line-height: 1.3;
  padding: 1px 4px;
}

th {
  background-color: #f0f0f0;
  font-weight: normal;
}

tfoot > tr.totalsRow {
  td {
    border-top: 1px solid #666;
    border-bottom: 2px solid #666;
    font-weight: bold;
    background-color: #eaeaea;
    color: #000;
  }

  .totalsName {
    text-transform: uppercase;
  }
}

tbody > tr.subTotalsRow {
  td {
    border-bottom: 2px solid #666;
    font-weight: bold;
  }
}

.actionOptions {
  margin-right: 1px;
  padding-top: 13px;
}

.titleContainer {
  padding: 13px 0 3px 0;
}

.container-fluid {
  padding-left: 16px;
  padding-right: 16px;
}

.thumbnail.addStuffThumbnail {
  margin-bottom: 0;
}

.tdAsset {
  .asset {
    display: inline-block;
    max-height: 2.5vw;
    line-height: 2.5vw;
    margin-bottom: 4px;

    img, video {
      display: inline-block;
      max-height: 2.5vw;
    }
  }
}

.thumbnail.thumbnailCaptionRight {
  display: inline-block;
  margin-bottom: 0;
  padding: 4px;
  border: 0;

  .asset {
    display: inline-block;
    max-height: 2.5vw;
    line-height: 2.5vw;
    margin-bottom: 4px;

    img, video {
      display: inline-block;
      max-height: 2.5vw;
    }
  }

  .caption {
    vertical-align: middle;
    padding: 0 0 0 3px;
    display: inline-block;
  }
}

.assets .caption, .caption {
  font-size: 11px;
}

.paginationContainer {
  font-size: 11px;

  ul {
    margin: 0;
  }

  .paginationLoading {
    a {
      pointer-events: none;
      cursor: not-allowed;
      filter: alpha(opacity=65);
      -webkit-box-shadow: none;
      box-shadow: none;
      opacity: .65;
    }
  }
}

ul.properListPadding {
  padding: 0 0 0 14px;
}

.fakeCheckbox {
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 2px 4px 2px 2px;

  &:hover {
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  &.fakeSelectedCheckbox {
    background-color: #e6e6e6;
    border-color: #8c8c8c;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .fa {
    position: relative;
    top: 1px;
  }
}

.thumbnail {
  transition: none;

  .caption {
    word-wrap: break-word;
  }

  .asset {
    text-align: center;
    min-height: 10vw;
    max-height: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    flex-direction: column;

    .htmlAssetContainer {
      display: table-cell;
      line-height: 1.2em;
      height: 10vw;
      vertical-align: middle;
      width: 300px;
    }

    .fa-spin {
      color: $gray-dark;
    }

    img, video {
      max-height: 10vw;
      display: inline-block;
      max-width: 100%;
      margin-top: -25px;
    }

    > object, > object > object {
      max-width: 100%;
      max-height: 10vw;
      line-height: 1;
    }

    .upload {
      height: 10vw;
      border: 2px dashed #ddd;
      padding: 2vw 4px 4px;
      line-height: 1.1;
      margin-top: -30px;
      width: 100%;

      p {
        margin: 0;
      }

      &.dragover {
        border-color: #337ab7;
      }
    }
  }
}

.assetConstraint {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: #eee;
  border: 1px dashed #ddd;
  font-weight: bold;
  color: #ccc;

  .assetConstraintTag {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.faSuccess {
  color: #5cb85c;
}

.faWarning {
  color: #f0ad4e;
}

.animate-fade {
  transition: opacity 0.15s linear 0.15s;

  &.delay {
    transition: opacity 0.15s linear 1s;
  }

  &.ng-leave.ng-leave-active, &.ng-move, &.ng-enter, &.ng-hide-add.ng-hide-add-active, &.ng-hide-remove {
    opacity: 0;
  }

  &.ng-leave, &.ng-move.ng-move-active, &.ng-enter.ng-enter-active, &.ng-hide-add, &.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
  }
}

.animate-width {
  transition: width linear .2s;
}

.fa-spin.ng-leave {
  transition: none;
  animation: none 0s;
  display: none;
}

.statusBlock {
  border: 1px solid black;
  min-width: 20px;
  border-radius: 2px;
  display: inline-block;
}

.statusBlockTall {
  min-height: 30px;
}

td .progress {
  margin-bottom: 0;
}

.progress.progressBarContainer {
  position: relative;
  margin-bottom: 0;

  .progress-bar.progressBar {
    position: absolute;
    left: 50%;

    &.negativeDirectionProgressBar {
      left: auto;
      right: 50%;
    }

    &.normalBar {
      background-color: #3b9cd2;
    }

    &.warningBar {
      background-color: #ff970d;
    }

    &.heavyWarningBar {
      background-color: #e44c55;
    }

    &.dangerBar {
      background-color: #c00d0d;
    }
  }
}

.numericColumn {
  text-align: right;
}

.tableSubEntry {
  padding-left: 14px !important;
}

.progressBarColumn, .table > tbody > tr > td.progressBarColumn {
  min-width: 120px;
  text-align: right;
  padding-top: 3px;
  position: relative;

  &:after {
    content: "";
    display: block;
    height: 15px;
    width: 1px;
    background-color: #ddd;
    position: absolute;
    top: 1px;
    left: 50%;
  }

  .progress {
    height: 10px;
    width: 95%;
    margin: auto;
  }
}

.tab-content {
  padding: 15px;
  margin-bottom: 15px;

  > .tab-pane > .panel:last-child {
    margin-bottom: 0;
  }
}

.resourceLabel {
  display: inline-block;
  margin-right: 1ex;
}

.header-utils .input-sm {
  font-size: 12px;
}

.modalFooterForm {
  text-align: left;
}

.collapsibleNav {
  a .fa {
    margin: 0 5px 0 0 !important;
  }

  .fa-chevron-right {
    font-size: 8px;
  }

  .fa-circle {
    font-size: 7px;
    position: relative;
    top: -2px;
  }

  .fa-fw:not(.fa-chevron-right):not(.fa-circle) {
    font-size: 13px;
    position: relative;
    top: 1px;
  }

  ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;

    li {
      a {
        position: relative;
        display: block;
        padding-left: 25px !important;
      }

      a:hover, a:active, a {
        text-decoration: none !important;
      }
    }
  }

  .divider {
    margin: auto 20px !important;
  }
}

uib-tab-heading .badge {
  margin-top: -3px;
  font-size: 10px;
  padding: 3px 5px;
}

@media (min-width: 768px) {
  $sidebarWidth: 168px;
  $sidebarCollapsibleWidth: 195px;

  .dl-horizontal {
    dt {
      text-align: left;
    }
  }

  #app.collapsibleLayout {
    main.layout {
      .sidebar {
        flex-basis: $sidebarCollapsibleWidth;
      }
    }

    .header-utils {
      flex-grow: 4 !important;

      form {
        flex: 0 0 auto !important;
        flex-grow: 4 !important;
        order: -1 !important;
        margin: 0 20px;
        text-align: center;

        .input-group {
          margin-left: auto;
          margin-right: auto;
          max-width: 600px;
        }
      }
    }

    .collapsibleNav {
      li li a {
        padding-left: 28px !important;
      }
    }

    .specialPanelThin {
      width: 300px;
    }

    header.app {
      .brand {
        flex: 0 0 $sidebarCollapsibleWidth;
      }

      .newButtonContainer {
        flex: 0 0 auto;
        order: 1;

        button {
          padding: 4px 7px;
          margin-right: 10px;
        }
      }

      .header-utils {
        form[role="search"] {
          width: 400px;
        }
      }
    }
  }

  #app {
    display: flex;
    flex-direction: column;
    height: 100vh;

    header, footer {
      flex: 0 0 auto;
    }

    main {
      flex: 1 1 auto;
      min-height: 0; // this lets us scroll the content panel.

      &.layout {

        display: flex;
        flex-direction: row-reverse;
        position: relative;

        & > aside {
          flex: 0 0 40px;
        }

        & > article {
          flex: 1 1 auto;
          overflow-y: auto;
          border: 0 none;
        }

        .sidebar {
          overflow-x: hidden;
          overflow-y: auto;
          flex-basis: $sidebarWidth;
          padding: 12px 0 0;
          display: flex;
          flex-direction: column;

          nav {
            flex: 1 1 auto;
          }

          .copyright {
            flex: 0 0 30px;
          }
        }

        .notifications {
          padding: 0;

          .notificationTabs {
            width: 45px;
            height: auto;
            z-index: 999;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            transition: width .2s ease;

            &.in {
              width: 445px;
            }

            .notificationTab {
              position: absolute;
              top: 0;
              left: 45px;
              height: 100%;
              width: 100%;

              .tabToggle {
                position: static;
                margin: 0 0 0 -40px;
                transition: all .2s;

                &:hover {
                  margin-left: -45px;
                  width: 45px;
                  padding-right: 5px;
                }
              }

              .tabContent {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                overflow-y: auto;
                width: 400px;
                z-index: 100;
              }
            }
          }
        }
      }
    }

    header.app {
      display: flex;
      align-items: center;
      padding: 0;

      .brand {
        order: 1;
        flex: 0 0 $sidebarWidth;

        a {
          text-decoration: none;
        }
      }

      .header-title {
        order: 2;
        flex: 1 1 0;

        .context-menu > a {
          margin-left: 15px;
        }

        .fa-chevron-down {
          font-size: 9px;
          margin-left: 4px;
          position: relative;
          top: -1px;
        }
      }

      .header-utils {
        order: 3;
        flex: 0 0 0;
        display: flex;
        align-items: center;

        form[role="search"] {
          order: 1;
          flex: 1 1 auto;
          width: 200px;
          margin-right: 10px;
        }

        .notificationContainer {
          order: 2;
          flex: 0 0 auto;
          padding: 0 10px 0 0;
          position: relative;

          .icon {
            font-size: 20px;
          }

          .badge {
            position: absolute;
            height: 12px;
            width: 12px;
            line-height: 10px;
            padding: 0;
            border-radius: 50%;
            display: inline-block;
            top: 0;
            right: 6px;
          }
        }

        .sessionInfo {
          order: 3;
          flex: 0 0 auto;
          padding: 0 5px;

          > a {
            padding-top: 3px;
            padding-bottom: 0;
          }
        }
      }

      .sessionInfo {
        .dropdown-panel {
          right: 0;
          left: auto;
          margin-top: 8px;
        }
      }
    }

    .dropdown-menu.dropdownWithArrow {
      left: 2px;
      margin-top: 14px;

      .disabled {
        a {
          color: #333;
          background-color: #f2f2f2;

          .fa-circle {
            margin-right: 5px;
          }
        }
      }

      &:before, &:after {
        content: "";
      }
    }
  }

  .adnDialog .modal-dialog {
    height: calc(100vh - 60px);

    .modal-content, .modal-content > form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .modal-content {

      .modal-header, .modal-footer {
        flex: 0 0 auto;
      }

      .modal-body {
        flex: 1 1 auto;
        overflow-y: auto;
        height: 0;

        &.adnTabs {
          overflow-y: visible;
        }
      }
    }
  }

  .adnTabs {
    display: flex;

    > .nav, > .nav ul {
      max-width: 200px;
      flex: 0 0 $colWidthThree;
      border-right: 1px solid #e5e5e5;
      padding-right: 15px;
      overflow-y: auto;
      overflow-x: hidden;

      h1, h2, h3, h4, h5 {
        margin-top: 0;
      }

      .overview {
        border-bottom: 1px dotted #ddd;
        padding-bottom: 2px;

        .fa {
          vertical-align: middle;
        }
      }
    }

    > .tab-content {
      flex: 1 1 auto;
      overflow: auto;
      padding: 0 15px;
      margin-right: -15px;
    }
  }

  .adnTabs.unborderedTabs {
    > .nav {
      border-right: none;
    }
  }
}

.targetingTemplateContainer {
  padding: 15px;
  border: 1px dashed #ccc;
  border-radius: 5px
}

.assetUploadContainer {
  border: 1px solid #ddd;
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;
  display: table;
  width: 100%;

  .upload {
    border: 2px dashed #ddd;
    line-height: 1.1;
    padding: 20px;
    margin-bottom: 5px;
    display: table-cell;
    width: 100%;

    &.dragover {
      border-color: #337ab7;
    }
  }

  .uploadButtonContainer {
    padding: 0 10px 0 15px;
    display: table-cell;
    vertical-align: middle;
  }
}

.criteriaRow {
  margin-bottom: 8px;
}

.panelSegments {
  .alert {
    margin: 0 10px;
  }

  .control-label {
    font-weight: normal;
  }
}

.sectionHighlight {
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #eee;

  h5 {
    padding-top: 0;
    margin-top: 0;
  }
}


.triggerConditions {
  select.form-control {
    width: auto;
    padding: 1px 2px;
  }

  .help-block {
    margin-bottom: 0;
  }

  .form-control {
    display: inline-block;
  }

  .adnDatePicker {
    > .form-control {
      display: table-cell;
      float: none;
    }
  }

  table.uib-timepicker {
    display: inline-table;

    tbody {
      display: inline-block;
    }

    td {
      vertical-align: middle;

      .form-control {
        display: block;
      }
    }
  }

  .btn-sm {
    padding: 3px 5px;
  }
}

@media (min-width: 768px) {
  #app header.app .header-utils form[role="search"] {
    width: 100px;
  }

  header.app .sessionInfo > a .networkIdentifier {
    display: none;
  }

  header.app .sessionInfo > a .userIdentifier .username {
    display: none;
  }
}

@media (min-width: 820px) {
  #app header.app .header-utils form[role="search"] {
    width: 160px;
  }
}

@media (min-width: 992px) {
  #app header.app .sessionInfo > a .networkIdentifier {
    display: block;
  }

  #app header.app .sessionInfo > a .userIdentifier .username {
    display: inline-block;
  }

  #app {
    header.app {
      .header-utils {
        form[role="search"] {
          width: 200px;
        }
      }
    }

    td.actions, th.actions {
      white-space: nowrap;

      .btn-group {
        display: inline-flex;
      }
    }
  }

  .assets {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-left: -10px;
    margin-right: -10px;

    .thumbnail {
      flex-basis: calc(#{$colWidthLg} - 20px);
      margin-left: 10px;
      margin-right: 10px;
      min-width: 120px;

      .assetFileName {
        width: calc(#{$colWidthLg} - 20px) px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &.active {
        background-color: #eee;
      }
    }

    &.assets-sm .thumbnail {
      flex-basis: calc(#{$colWidthSm} - 20px);

      .asset {
        line-height: 5vw;
        max-height: 5vw;

        > img, > object, > object > object, > video {
          max-height: 5vw;
        }
      }
    }

    &.assets-lg .thumbnail {
      flex-basis: calc(#{$colWidthLg} - 20px);

      .asset {
        line-height: 15vw;
        max-height: 15vw;

        > img, > object, > object > object, > video {
          max-height: 15vw;
        }
      }
    }
  }

  .modal-xl {
    width: auto;
    margin: 30px;
  }
}

@media (min-width: 1200px) {
  #app header.app {
    .header-utils {
      form[role="search"] {
        width: 300px;
      }
    }
  }
}

.defaultLogo {
  position: relative;
  top: -6px;
}

@media (min-width: 768px) {
  .defaultLogo {
    top: 4px;
  }
}

.denuded {
  header {
    height: 33px;
  }

  header a:hover {
    text-decoration: none;
  }

  .container-fluid {
    margin: 0;
  }

  footer {
    a {
      text-decoration: underline;
      color: $text-muted;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

/* Have to set height explicity on ui-view
to prevent collapsing during animation*/

.animateTransition .ui-view-container {
  position: relative;
}

.animateTransition .ui-view-container [ui-view] {
  &.ng-enter, &.ng-leave {
    position: absolute;
    left: 0;
    right: 0;
    transition: all .2s ease-in-out;
  }
}

.animateTransition.animateTransitionUp .ui-view-container [ui-view] {
  &.ng-enter {
    transform: translate(0, 200px);
  }

  &.ng-enter-active {
    transform: translate(0, 0);
  }

  &.ng-leave {
    opacity: .15;
    transform: translate(0, 0);
  }

  &.ng-leave-active {
    transform: translate(400px, 0);
    opacity: 0;
  }
}

.animateTransition.animateTransitionDown .ui-view-container [ui-view] {
  &.ng-enter {
    transform: translate(200px, 0px);
  }

  &.ng-enter-active {
    transform: translate(0, 0);
  }

  &.ng-leave {
    transform: translate(0, 0);
    opacity: .15;
  }

  &.ng-leave-active {
    transform: translate(0, 400px);
    opacity: 0;
  }
}

.specialPanel {
  z-index: 10;
  position: absolute;
  background-color: #fff;
  width: 45%;
  max-width: 550px;
  padding: 0 10px 10px 10px;
  right: 10px;
  top: 10px;
  border-radius: 6px;
  box-shadow: 0 0 3px 3px #ddd;
  overflow: auto;
  max-height: 95%;

  h3 {
    margin-top: 10px;
  }

  .subSpecialPanel {
    max-height: 400px;
    overflow: auto;
  }
}

.collapsibleLayout {
  .subSpecialPanel {
    max-height: inherit;
  }
}

.glyphicon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1.28571429em;
  text-align: center;
}

.glyphicon-chevron-left:before {
  content: "\f053";
}

.glyphicon-chevron-right:before {
  content: "\f054";
}

.isInvisible {
  visibility: hidden;
}

.allTeamsSelector {
  margin: 6px 0;

  a {
    user-select: none;
  }
}

.filterOptions {
  label {
    font-weight: normal;
  }

  select {
    width: 95%;
  }
}

.blacklistAdvertiserDomains {
  tags-input .tags .tag-item {
    background-color: #fff;
    color: #333;
    border-color: #d4d4d4;
    font-size: 10px;

    &:hover {
      background-color: #e6e6e6;
      border-color: #adadad;
    }

    .remove-button {
      color: #8c8c8c;
      font-size: 14px;
      margin-left: 1px;
      position: relative;
      top: -1px;
    }
  }
}

tags-input .tags .tag-item .remove-button {
  padding: 0 5px 0 10px;
}

tags-input .tags .tag-item .remove-button:hover {
  color: #fff;
  font-weight: bold;
}

.executionLabelContainer {
  display: inline-block;
  margin-bottom: 5px;
}

.pCopy {
  strong {
    position: relative;
    top: 5px;
  }
}

h1 {
  word-break: break-all;
}

.breadcrumbContainer a {
  word-break: break-all;
}

.tableCondensedTd {
  td {
    overflow-wrap: break-word;
    max-width: 185px;
  }

  .longCellWrap {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.tooltip-inner {
  overflow-wrap: break-word;
}

.dropdownButtonContainer {
  margin: 7px 0 9px 0;
}

.fixedWidthInputGroup {
  min-width: 40px;
}

.demo-text {
  background-color: #fff;
  padding: 3px;
  position: relative;
  top: 3px;
}

.demo-div {
  width: 300px;
  height: 200px;
  background: none;
  border: 2px solid #183147;
  box-shadow: 0 0 0 3000px #000000aa;
  position: absolute;
  font-family: sans-serif;
  text-align: center;
  z-index: 100;
  cursor: move;
}

.selectedCrop {
  background-color: #eee;
}

.videoContainerContainer {
  max-height: 800px;
  overflow: auto;
  display: inline-block;
}

.videoUpload {
  .assets {
    display: block;
    margin-left: auto;
    margin-right: auto;

    .thumbnail {
      min-width: 300px;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;

      .asset {
        min-height: 20vw;
        max-height: 20vw;

        .upload {
          height: 20vw;
          padding: 7vw 10px 10px;
        }
      }
    }
  }
}

.videoInfoBox {
  border-radius: 3px;
  padding: 5px;
  border: 2px dotted #ddd;
}

.animateOff {
  display: none;
}

.adnProgressBar {
  position: absolute;
  font-size: 11px;
  line-height: 11px;
  left: 0;
}

.emptyListEntries {
  li:empty {
    display: none;
  }
}