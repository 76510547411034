$defaultColor: #666;
$advColor: #4466a0;
$invColor: #225555;
$repColor: #222;

.micon {
  border-radius: 3px;
  width: 18px;
  padding: 3px 0;
  position: relative;
  top: -2px;
  display: inline-block;
  text-align: center;
  background-color: #666;
  font-size: 9px;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #fff;
}

.miconFolder {
  background-color: $advColor;

  &:before {
    content: 'Fo';
  }
}

.miconTrigger {
  background-color: $advColor;

  &:before {
    content: 'Tr';
  }
}

.miconSegment {
  background-color: $advColor;

  &:before {
    content: 'Se';
  }
}

.miconFieldMapping {
  background-color: $advColor;

  &:before {
    content: 'Ma';
  }
}

.miconLineItem {
  background-color: $advColor;

  &:before {
    content: 'LI';
  }
}

.miconCreativeSet {
  background-color: $advColor;

  &:before {
    content: 'CS';
  }
}

.miconWorkspace {
  &:before {
    content: 'Da';
  }
}

.miconAdvertiser {
  background-color: $advColor;

  &:before {
    content: 'Av';
  }
}

.miconAdUnit {
  background-color: $invColor;

  &:before {
    content: 'AU';
  }
}

.miconExternalAdUnit {
  background-color: $invColor;

  &:before {
    content: 'Ex';
  }
}

.miconCreative {
  background-color: $advColor;

  &:before {
    content: 'Cr';
  }
}

.miconLibraryCreative {
  background-color: $advColor;

  &:before {
    content: 'LC';
  }
}

.miconExclusionLabel {
  background-color: $advColor;

  &:before {
    content: 'EL';
  }
}

.miconMatchingLabel {
  background-color: $invColor;

  &:before {
    content: 'ML';
  }
}

.miconReachAnalysis {
  background-color: $advColor;

  &:before {
    content: 'RA';
  }
}

.miconBookingCalendar {
  background-color: $advColor;

  &:before {
    content: 'BC';
  }
}

.miconLayout {
  &:before {
    content: 'Ly';
  }
}

.miconLayoutInclude {
  &:before {
    content: 'LI';
  }
}

.miconCdnUpload {
  &:before {
    content: 'CU';
  }
}

.miconLabel {
  &:before {
    content: 'La';
  }
}

.miconRateControlLabel {
  &:before {
    content: 'RL';
  }
}

.miconReport {
  background-color: $repColor;

  &:before {
    content: 'Re';
  }
}

.miconInvoiceTranslation {
  background-color: $repColor;

  &:before {
    content: 'IT';
  }
}

.miconReportTemplate {
  background-color: $repColor;

  &:before {
    content: 'RT';
  }
}

.miconReportSchedule {
  background-color: $repColor;

  &:before {
    content: 'RS';
  }
}

.miconCustomEvent {
  &:before {
    content: 'CE';
  }
}

.miconNetwork {
  &:before {
    content: 'Ne';
  }
}

.miconRole {
  &:before {
    content: 'Ro';
  }
}

.miconEarningsAccount {
  background-color: $invColor;

  &:before {
    content: 'EA';
  }
}

.miconSite {
  background-color: $invColor;

  &:before {
    content: 'Si';
  }
}

.miconBlocklist {
  background-color: $invColor;

  &:before {
    content: 'Bl';
  }
}

.miconRuleset {
  background-color: $invColor;

  &:before {
    content: 'Ru';
  }
}

.miconSiteGroup {
  background-color: $invColor;

  &:before {
    content: 'SG';
  }
}

.miconTeam {
  &:before {
    content: 'Te';
  }
}

.miconTeamGroup {
  &:before {
    content: 'TG';
  }
}

.miconQuery {
  background-color: $repColor;

  &:before {
    content: 'Qu';
  }
}

.miconUser {
  &:before {
    content: 'Us';
  }
}

.miconUserProfile {
  &:before {
    content: 'UP';
  }
}

.miconUserSegment {
  &:before {
    content: 'US';
  }
}

.miconNotificationPreferences {
  &:before {
    content: 'NP';
  }
}

.miconOrder {
  background-color: $advColor;

  &:before {
    content: 'Or';
  }
}

.miconFolio {
  background-color: $advColor;

  &:before {
    content: 'Fo';
  }
}

.miconReferenceData {
  &:before {
    content: 'RD';
  }
}

.miconContextServiceConfiguration {
  &:before {
    content: 'CS';
  }
}

.miconMarketplaceProduct {
  &:before {
    content: 'MP';
  }
}

.miconProduct {
  &:before {
    content: 'Pr';
  }
}

.miconCoupon {
  &:before {
    content: 'Co';
  }
}

.miconEmailTranslation {
  &:before {
    content: 'ET';
  }
}

.miconExternalDemandSource {
  &:before {
    content: 'ES';
  }
}

.miconDataExport {
  &:before {
    content: 'DE';
  }
}

.miconNetwork {
  &:before {
    content: 'Ne';
  }
}

.miconTier {
  &:before {
    content: 'Ti';
  }
}
