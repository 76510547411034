$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, Calibri, 'Calibri Light', sans-serif;
$font-size-base: 12px;
$table-condensed-cell-padding: 4px;
$form-group-margin-bottom: 10px;
$padding-base-vertical: 4px;
$padding-base-horizontal: 6px;
$grid-gutter-width: 20px;
$navbar-height: 36px;
$alert-padding: 8px;
$modal-inner-padding: 14px;
$modal-title-padding: 14px;
$panel-body-padding: 10px;
$nav-link-padding: 5px 8px;
$panel-heading-padding: 8px 12px;
$line-height-base: 1.25;